import { useState } from "react";
import Menu from "./components/Menu";
import SlideMenu from "./components/SlideMenu";

import ProfileCard from "./components/ProfileCard";
import Introduce from "./components/Introduce";
import About from "./components/About";
import Service from "./components/Service";
import BackgroundAnimation from "./components/BackgroundAnimation";
import Skills from "./components/Skills";
import Resume from "./components/Resume";
import Contact from "./components/Contact";
import Topbar from "./components/Topbar";

function App() {
  const [sections, setSections] = useState([
    {
      title: "Home",
      icon: require("./images/Menu/Home.png"),
      coloredIcon: require("./images/Menu/Colored/ColoredHome.png"),
      topOffset: 500,
    },
    {
      title: "About",
      icon: require("./images/Menu/About.png"),
      coloredIcon: require("./images/Menu/Colored/ColoredAbout.png"),
      topOffset: 600,
    },
    {
      title: "Service",
      icon: require("./images/Menu/Service.png"),
      coloredIcon: require("./images/Menu/Colored/ColoredService.png"),
      topOffset: 0,
    },
    {
      title: "Skills",
      icon: require("./images/Menu/Skills.png"),
      coloredIcon: require("./images/Menu/Colored/ColoredSkills.png"),
      topOffset: 0,
    },
    {
      title: "Resume",
      icon: require("./images/Menu/Resume.png"),
      coloredIcon: require("./images/Menu/Colored/ColoredResume.png"),
      topOffset: 0,
    },
    {
      title: "Contact",
      icon: require("./images/Menu/Contact.png"),
      coloredIcon: require("./images/Menu/Colored/ColoredContact.png"),
      topOffset: 0,
    },
  ]);
  const [shareLinks, setShareLinks] = useState([
    {
      title: "X",
      icon: require("./images/Menu/Share/X.png"),
      coloredIcon: require("./images/Menu/Share/Colored/X.png"),
      link: "http://google.com",
    },
    {
      title: "Instagram",
      icon: require("./images/Menu/Share/Instagram.png"),
      coloredIcon: require("./images/Menu/Share/Colored/Instagram.png"),
      link: "http://google.com",
    },
    {
      title: "Telegram",
      icon: require("./images/Menu/Share/Telegram.png"),
      coloredIcon: require("./images/Menu/Share/Colored/Telegram.png"),
      link: "http://google.com",
    },
    {
      title: "Linkdin",
      icon: require("./images/Menu/Share/Linkdin.png"),
      coloredIcon: require("./images/Menu/Share/Colored/Linkdin.png"),
      link: "http://google.com",
    },
  ]);

  const [sliderMenuIsOpen, setSliderMenuIsOpen] = useState(false);

  const goToLastSection = () => {
    const element = document.getElementById(
      `${sections[sections.length - 1].title}`
    );
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  return (
    <div id="outer-container">
      <SlideMenu
        isOpen={sliderMenuIsOpen}
        onClose={() => setSliderMenuIsOpen(false)}
        close={() => setSliderMenuIsOpen(false)}
        sections={sections}
        shareLinks={shareLinks}
      />

      <div
        id="page-wrap"
        className="w-[100vw] h-[100dvh] bg-power__black overflow-x-hidden relative xl:block flex flex-col items-center"
      >
        <BackgroundAnimation />

        <div className="w-full pt-16 xl:pt-0 px-3 2xl:px-[60px] h-full overflow-auto flex xl:flex-row flex-col justify-between gap-x-3 overflow-y-auto">
          <Topbar setSliderMenuIsOpen={setSliderMenuIsOpen} />

          <div className="xl:w-[22.5rem] xl:block w-full flex justify-center z-10 ">
            <div className="w-full md:w-fit xl:absolute top-0 h-[100dvh] flex flex-col justify-center">
              <ProfileCard />
            </div>
          </div>

          <div className="xl:py-14 pt-0 flex-1 flex flex-col gap-y-5 z-10 overflow-x-clip w-full lg:w-[940px] mx-auto items-center">
            <Introduce goToLastSection={goToLastSection} />
            <About />
            <Service />
            <Skills />
            <Resume />
            <Contact />

            <span className="text-center py-8 text-colorGray">
              Copyright by @alirezanasiri.pro
            </span>
          </div>

          <div className="hidden w-[6rem] xl:flex justify-center">
            <div className="absolute top-0 h-[100dvh] flex flex-col justify-center items-center z-20">
              <Menu sections={sections} shareLinks={shareLinks} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
