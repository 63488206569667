import React, { useState } from "react";

import ColoredService from "../images/Menu/Colored/ColoredService.png";

export default function Service() {
  const [hoveredIndex, setHoveredIndex] = useState(-1);

  const [services, setServices] = useState([
    {
      title: "UI/UX Design",
      descripton:
        "I design products that are more than pretty. I make them shippable.",
      icon: require("../images/Service/Colored/design.png"),
    },
    {
      title: "Web Development",
      descripton:
        "I design products that are more than pretty. I make them shippable.",
      icon: require("../images/Service/Colored/development.png"),
    },
  ]);

  return (
    <section
      id="Service"
      className="scroll-mt-20 xl:scroll-mt-4 w-full bg-nightBlack rounded-2xl p-12 flex flex-col"
    >
      <div className="border border-metalBlack rounded-full flex items-center gap-x-2 px-5 py-2 w-fit">
        <img className="w-4 h-4" src={ColoredService} alt="Services" />
        <span className="text-snowWhite uppercase text-xs font-medium">
          services
        </span>
      </div>

      <div className="w-full flex flex-col gap-y-7 mt-8">
        <span className="text-snowWhite text-[2.1rem] lg:text-5xl -my-4 font-extralight">
          My <span className="text-themeColor font-medium">Services</span>
        </span>

        <span className="text-colorGray max-w-[650px] leading-[30px]">
          I design products that are more than pretty. I make them usable & fit
          to your demand, I make the best ones.
        </span>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
          {services.map((service, index) => (
            <div
              key={index}
              className="col-span-1 border rounded-2xl border-metalBlack p-5 md:p-10 flex flex-col gap-y-4"
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(-1)}
            >
              <div className="w-full flex justify-between items-center">
                <span
                  className={`text-5xl md:text-6xl lg:text-7xl font-extrabold text-stroke text-snowWhite transition-all duration-300 ${
                    hoveredIndex !== index ? "opacity-30" : "opacity-100"
                  }`}
                >
                  {index < 10 ? "0" + (index + 1) : index + 1}
                </span>
                <img
                  className={`w-10 h-10 md:w-12 md:h-12 transition-all duration-300 ${
                    hoveredIndex !== index ? "" : "-rotate-45"
                  }`}
                  src={service.icon}
                  alt={service.title}
                />
              </div>

              <span className="text-snowWhite text-xl xl:text-2xl font-medium">
                {service.title}
              </span>

              <span className="text-colorGray leading-[1.9rem]">
                {service.descripton}
              </span>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
