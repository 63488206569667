import React, { useRef } from "react";
import { slide as Slide } from "react-burger-menu";

export default function SlideMenu({
  isOpen,
  onClose,
  close,
  sections,
  shareLinks,
}) {
  const SliderMenuStyles = {
    bmBurgerButton: {
      display: "hidden",
    },
    bmBurgerBars: {
      background: "#18191a",
    },
    bmBurgerBarsHover: {
      background: "#a90000",
    },
    bmCrossButton: {
      padding: "20px",
      background: "#434445",
      borderRadius: "100%",
      right: "15px",
      top: "15px",
    },
    bmCross: {
      background: "#F2F5F8",
      position: "absolute",
      right: "5px",
      top: "7px",
    },
    bmMenuWrap: {
      position: "fixed",
      height: "100%",
    },
    bmMenu: {
      background: "#212223",
      padding: "2.5em 1rem 0rem",
      fontSize: "1.15em",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    bmMorphShape: {
      fill: "#373a47",
    },
    bmItemList: {
      color: "#b8b7ad",
      padding: "0.8em",
    },
    bmItem: {
      display: "inline-block",
    },
    bmOverlay: {
      background: "rgba(0, 0, 0, 0.5)",
    },
  };

  const SlideMenuRef = useRef();

  return (
    <Slide
      ref={SlideMenuRef}
      width={"200px"}
      right
      isOpen={isOpen}
      pageWrapId={"page-wrap"}
      outerContainerId={"outer-container"}
      onClose={onClose}
      styles={SliderMenuStyles}
    >
      <div className="w-full h-full flex flex-col justify-between relative">
        <div className="">
          <span className="text-snowWhite font-medium text-lg">Menu</span>
          <div className="w-full">
            {sections.map((section, index) => (
              <button
                onClick={() => {
                  const element = document.getElementById(`${section.title}`);
                  if (element) {
                    // 👇 Will scroll smoothly to the top of the next section
                    element.scrollIntoView({
                      behavior: "smooth",
                    });
                    close();
                  }
                }}
                className="w-full flex items-center gap-x-2 my-7 text-colorGray transition-all duration-100 hover:text-themeColor"
              >
                <img
                  className="w-5 h-5"
                  src={section.icon}
                  alt={section.title}
                />
                <span className="text-[15.5px]">{section.title}</span>
              </button>
            ))}
          </div>
        </div>

        <div className="absolute bottom-7">
          <span className="text-snowWhite font-medium text-lg">
            Get in Touch
          </span>
          <div className="flex items-center gap-x-2.5 py-1 mt-2.5">
            {shareLinks.map((shareLink, index) => (
              <a
                href={shareLink.link}
                className="opacity-60 transition-all duration-200 hover:opacity-100"
              >
                <img
                  className="w-5 h-5"
                  src={shareLink.icon}
                  alt={shareLink.title}
                />
              </a>
            ))}
          </div>
        </div>
      </div>
    </Slide>
  );
}
