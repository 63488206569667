import React, { useEffect, useRef, useState } from "react";

import ColoredSkills from "../images/Menu/Colored/ColoredSkills.png";
import ArrowLeft from "../images/Skills/arrow-left.png";
import ArrowRight from "../images/Skills/arrow-right.png";
import ActiveArrowLeft from "../images/Skills/Active/active-arrow-left.png";
import ActiveArrowRight from "../images/Skills/Active/active-arrow-right.png";

import Figma from "../images/Skills/skills/figma.svg";
import Html from "../images/Skills/skills/html.svg";
import Css from "../images/Skills/skills/css.svg";
import Js from "../images/Skills/skills/js.svg";
import TailwindCSS from "../images/Skills/skills/tailwind.svg";
import ReactJS from "../images/Skills/skills/react.svg";
import ProgressProvider from "./common/ProgressProvider";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { useInView } from "react-intersection-observer";

export default function Skills() {
  const [skills, setSkills] = useState([
    {
      title: "Figma",
      icon: Figma,
      percent: 74,
    },
    {
      title: "HTML",
      icon: Html,
      percent: 96,
    },
    {
      title: "CSS",
      icon: Css,
      percent: 88,
    },
    {
      title: "JS",
      icon: Js,
      percent: 92,
    },
    {
      title: "TailwindCSS",
      icon: TailwindCSS,
      percent: 78,
    },
    {
      title: "ReactJS",
      icon: ReactJS,
      percent: 85,
    },
  ]);

  const [activeSkillPage, setActiveSkillPage] = useState(skills.length ? 1 : 0);

  const [arrowHover, setArrowHover] = useState("");

  const getSliderShowQuantity = () => {
    return 4;
  };

  const getTransformStep = () => {
    if (window.innerWidth < 1280) {
      return -216;
    } else if (window.innerWidth < 1536) {
      return -176;
    }
    return -208;
  };
  const [transformSlider, setTransformSlider] = useState(0);

  useEffect(() => {
    setTransformSlider((activeSkillPage - 1) * getTransformStep());
  }, [activeSkillPage]);

  const [progressDisabled, setProgressDisabled] = useState(true);

  const { ref: inViewRef, inView } = useInView({
    threshold: 0,
  });

  const handleScroll = () => {
    setProgressDisabled(false);
  };

  useEffect(() => {
    if (inView) {
      handleScroll();
    }
  }, [inView, handleScroll]);

  return (
    <section
      id="Skills"
      className="scroll-mt-20 xl:scroll-mt-4 w-full bg-nightBlack rounded-2xl p-12 flex flex-col"
    >
      <div className="border border-metalBlack rounded-full flex items-center gap-x-2 px-5 py-2 w-fit">
        <img className="w-4 h-4" src={ColoredSkills} alt="Services" />
        <span className="text-snowWhite uppercase text-xs font-medium">
          skills
        </span>
      </div>

      <div className="w-full flex flex-col gap-y-7 mt-8">
        <div className="w-full flex items-center justify-between">
          <span className="text-snowWhite text-[2.1rem] lg:text-5xl -my-4 font-extralight">
            My <span className="text-themeColor font-medium">Advantages</span>
          </span>
          <div className="hidden lg:flex items-center gap-x-1.5">
            <button
              onMouseEnter={() => setArrowHover("left")}
              onMouseLeave={() => setArrowHover("")}
              className="p-3 border border-metalBlack rounded-full transition-all duration-300 hover:bg-themeColor"
              onClick={() =>
                setActiveSkillPage((prev) => (prev > 1 ? prev - 1 : prev))
              }
            >
              <img
                className="w-5 h-5"
                src={arrowHover !== "left" ? ArrowLeft : ActiveArrowLeft}
              />
            </button>
            <span className="text-snowWhite w-10 text-center">
              <span className="text-themeColor">{activeSkillPage}</span>/
              {skills.length}
            </span>
            <button
              onMouseEnter={() => setArrowHover("right")}
              onMouseLeave={() => setArrowHover("")}
              className="p-3 border border-metalBlack rounded-full transition-all duration-300 hover:bg-themeColor"
              onClick={() =>
                setActiveSkillPage((prev) =>
                  prev < skills.length - getSliderShowQuantity() + 1
                    ? prev + 1
                    : prev
                )
              }
            >
              <img
                className="w-5 h-5"
                src={arrowHover !== "right" ? ArrowRight : ActiveArrowRight}
              />
            </button>
          </div>
        </div>

        <span className="text-colorGray w-full md:max-w-[650px] leading-[30px]">
          I design products that are more than pretty. I make them usable & fit
          to your demand, I make the best ones.
        </span>

        <div className="w-full overflow-hidden">
          <div
            ref={inViewRef}
            className={`flex justify-center lg:justify-start items-center flex-wrap lg:flex-nowrap gap-x-5 md:gap-x-12 transition-all duration-700`}
            style={{ transform: `translateX(${transformSlider}px` }}
          >
            {skills.map((skill, index) => (
              <div className="flex items-center">
                <div
                  key={index}
                  className="flex flex-col items-center gap-y-4 mt-5"
                >
                  <img
                    className="w-14 h-14 grayscale"
                    src={skill.icon}
                    alt={skill.title}
                  />

                  <div className="w-32 md:w-40 lg:w-[10.5rem] xl:w-32 2xl:w-40">
                    <ProgressProvider
                      valueStart={0}
                      valueEnd={skill.percent}
                      disabled={progressDisabled}
                      stepDuration={8}
                    >
                      {(value) => (
                        <CircularProgressbar
                          value={value}
                          text={`${value}%`}
                          strokeWidth={1}
                          className="text-colorGray"
                          styles={{
                            path: {
                              transition: "linear 0.5s ease 0s",
                              // Rotate the path
                              transform: "rotate(0.25turn)",
                              transformOrigin: "center center",
                            },
                            text: {
                              fontWeight: "bold",
                            },
                            ...buildStyles({
                              // Rotation of path and trail, in number of turns (0-1)
                              rotation: 0.25,

                              // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                              strokeLinecap: "butt",

                              // Text size
                              textSize: "16px",

                              // How long animation takes to go from one percentage to another, in seconds
                              pathTransitionDuration: 0.5,

                              // Can specify path transition in more detail, or remove it entirely
                              // pathTransition: 'none',

                              // Colors
                              pathColor: "#00bc91",
                              textColor: "#fff",
                              trailColor: "#999999",
                              backgroundColor: "#fff",
                            }),
                          }}
                        />
                      )}
                    </ProgressProvider>
                  </div>

                  <span className="text-snowWhite font-medium">
                    {skill.title}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
