import React, { useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";

import ColoredAbout from "../images/Menu/Colored/ColoredAbout.png";
import ProgressProvider from "./common/ProgressProvider";

export default function About() {
  const [projectPercents, setProjectPercents] = useState([
    {
      title: "HTML5 & CSS3",
      percent: 94,
    },
    {
      title: "TailwindCSS",
      percent: 88,
    },
    {
      title: "Flowbite",
      percent: 35,
    },
  ]);

  const [progressDisabled, setProgressDisabled] = useState(true);

  const { ref: inViewRef, inView } = useInView({
    threshold: 0,
  });

  const handleScroll = () => {
    setProgressDisabled(false);
  };

  useEffect(() => {
    if (inView) {
      handleScroll();
    }
  }, [inView, handleScroll]);

  return (
    <section
      id="About"
      className="scroll-mt-20 xl:scroll-mt-4 w-full bg-nightBlack rounded-2xl p-12 flex flex-col"
    >
      <div className="border border-metalBlack rounded-full flex items-center gap-x-2 px-5 py-2 w-fit">
        <img className="w-4 h-4" src={ColoredAbout} alt="Introduce" />
        <span className="text-snowWhite uppercase text-xs font-medium">
          about me
        </span>
      </div>

      <div className="w-full flex flex-col gap-y-7 mt-8">
        <span className="text-snowWhite font-extralight text-[2.1rem] lg:text-5xl -my-4">
          About <span className="text-themeColor font-medium">Me</span>
        </span>

        <span className="text-colorGray max-w-[650px] leading-[30px]">
          Hi, my name is <span className="text-snowWhite">Alireza Nasiri</span>{" "}
          and I began with frontend development when first began. spent most of
          my working hours for the last three years designing, programming and
          operating websites and{" "}
          <span className="text-snowWhite">make them the best possible</span>.
        </span>

        <div className="flex flex-wrap items-center gap-2">
          {projectPercents.map((e, i) => (
            <span className="border border-dashed border-greyBlack rounded-full px-5 py-2 text-sm transition-all duration-200 text-colorGray hover:text-snowWhite">
              {e.title + " (" + e.percent + "%)"}
            </span>
          ))}
        </div>

        <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-y-3.5 text-colorGray">
          <div className="col-span-1 flex items-center text-colorGray text-sm">
            <span className="w-[6rem]">Phone</span>
            <span className="">:</span>
            <span className="ml-7 text-snowWhite font-medium">
              +(98) 938 612 7081
            </span>
          </div>
          <div className="col-span-1 flex items-center text-colorGray text-sm">
            <span className="w-[6rem]">Email</span>
            <span className="">:</span>
            <span className="ml-7 text-snowWhite font-medium">
              alirezanasiri784@gmail.com
            </span>
          </div>
          <div className="col-span-1 flex items-center text-colorGray text-sm">
            <span className="w-[6rem]">Telegram</span>
            <span className="">:</span>
            <span className="ml-7 text-snowWhite font-medium">
              t.me/alirezansr81
            </span>
          </div>
          <div className="col-span-1 flex items-center text-colorGray text-sm">
            <span className="w-[6rem]">Github</span>
            <span className="">:</span>
            <span className="ml-7 text-snowWhite font-medium">
              github.com/alirezans81
            </span>
          </div>
          <div className="col-span-1 flex items-center text-colorGray text-sm">
            <span className="w-[6rem]">Language</span>
            <span className="">:</span>
            <span className="ml-7 text-snowWhite font-medium">
              English, Farsi
            </span>
          </div>
        </div>

        <div
          ref={inViewRef}
          className="w-full grid grid-cols-2 md:grid-cols-4 gap-y-4 md:gap-y-0"
        >
          <div className="col-span-1 flex flex-col">
            <ProgressProvider
              valueStart={0}
              valueEnd={3}
              stepDuration={100}
              disabled={progressDisabled}
            >
              {(value) => (
                <span className="text-themeColor text-[1.6rem] md:text-[2rem] font-semibold">
                  {value + "+"}
                </span>
              )}
            </ProgressProvider>
            <span className="text-colorGray text-sm capitalize -mt-1.5">
              years of experience
            </span>
          </div>
          <div className="col-span-1 flex flex-col">
            <ProgressProvider
              valueStart={0}
              valueEnd={12}
              stepDuration={50}
              disabled={progressDisabled}
            >
              {(value) => (
                <span className="text-themeColor text-[1.6rem] md:text-[2rem] font-semibold">
                  {value + "+"}
                </span>
              )}
            </ProgressProvider>
            <span className="text-colorGray text-sm capitalize -mt-1.5">
              handled projects
            </span>
          </div>
          <div className="col-span-1 flex flex-col">
            <ProgressProvider
              valueStart={0}
              valueEnd={3}
              stepDuration={100}
              disabled={progressDisabled}
            >
              {(value) => (
                <span className="text-themeColor text-[1.6rem] md:text-[2rem] font-semibold">
                  {value}
                </span>
              )}
            </ProgressProvider>
            <span className="text-colorGray text-sm capitalize -mt-1.5">
              projects in hand
            </span>
          </div>
          <div className="col-span-1 flex flex-col">
            <ProgressProvider
              valueStart={0}
              valueEnd={82}
              stepDuration={6}
              disabled={progressDisabled}
            >
              {(value) => (
                <span className="text-themeColor text-[1.6rem] md:text-[2rem] font-semibold">
                  {value + "%"}
                </span>
              )}
            </ProgressProvider>
            <span className="text-colorGray text-sm capitalize -mt-1.5">
              avg satisfaction percent
            </span>
          </div>
        </div>
      </div>
    </section>
  );
}
