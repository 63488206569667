import React from "react";

import ColoredResume from "../images/Menu/Colored/ColoredResume.png";

export default function Resume() {
  return (
    <section
      id="Resume"
      className="scroll-mt-20 xl:scroll-mt-4 w-full bg-nightBlack rounded-2xl p-12 flex flex-col"
    >
      <div className="border border-metalBlack rounded-full flex items-center gap-x-2 px-5 py-2 w-fit">
        <img className="w-4 h-4" src={ColoredResume} alt="Services" />
        <span className="text-snowWhite uppercase text-xs font-medium">
          resume
        </span>
      </div>

      <div className="flex flex-col gap-y-8">
        <div className="w-full flex flex-col gap-y-7 mt-8">
          <span className="text-snowWhite text-[2.1rem] lg:text-5xl -my-4 font-extralight">
            Work <span className="text-themeColor font-medium">Experience</span>
          </span>

          <span className="text-colorGray max-w-[650px] leading-[30px]">
            I design products that are more than pretty. I make them usable &
            fit to your demand, I make the best ones.
          </span>

          <div className="flex flex-col gap-y-3 md:gap-y-0">
            <div className="w-full flex flex-col md:flex-row gap-x-10 mt-2 border md:border-none border-metalBlack rounded-xl p-6 md:p-0">
              <div className="w-full md:w-2/6 md:h-40 md:border-r border-metalBlack relative">
                <div className="hidden md:block absolute -right-1">
                  <div className="w-2 h-2 bg-themeColor rounded-full outline outline-8 outline-greyBlack"></div>
                </div>

                <div className="flex w-full md:w-fit justify-between md:justify-start md:flex-col -mt-2">
                  <span className="text-snowWhite text-[16px] font-medium">
                    Xbarat
                  </span>
                  <span className="text-colorGray text-[14px]">
                    Nov, 2022 - Current
                  </span>
                </div>
              </div>
              <div className="w-full md:w-4/6 md:pl-10 mt-7 md:-mt-2.5">
                <div className="flex flex-col gap-y-2 md:gap-y-4">
                  <span className="text-snowWhite font-medium text-xl">
                    Lead UI/UX Deigner & Frontend Development
                  </span>
                  <span className="text-colorGray leading-[30px]">
                    Owing to advancements in product other designer technologies
                    aute voluptate.
                  </span>
                </div>
              </div>
            </div>

            <div className="w-full flex flex-col md:flex-row gap-x-10 mt-2 border md:border-none border-metalBlack rounded-xl p-6 md:p-0">
              <div className="w-full md:w-2/6 md:h-40 md:border-r border-metalBlack relative">
                <div className="hidden md:block absolute -right-1">
                  <div className="w-2 h-2 bg-themeColor rounded-full outline outline-8 outline-greyBlack"></div>
                </div>

                <div className="flex w-full md:w-fit justify-between md:justify-start md:flex-col -mt-2">
                  <span className="text-snowWhite text-[16px] font-medium">
                    Xbarat
                  </span>
                  <span className="text-colorGray text-[14px]">
                    Nov, 2022 - Current
                  </span>
                </div>
              </div>
              <div className="w-full md:w-4/6 md:pl-10 mt-7 md:-mt-2.5">
                <div className="flex flex-col gap-y-2 md:gap-y-4">
                  <span className="text-snowWhite font-medium text-xl">
                    Lead UI/UX Deigner & Frontend Development
                  </span>
                  <span className="text-colorGray leading-[30px]">
                    Owing to advancements in product other designer technologies
                    aute voluptate.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full flex flex-col gap-y-7 mt-8">
          <span className="text-snowWhite text-[2.1rem] lg:text-5xl -my-4 font-extralight">
            My <span className="text-themeColor font-medium">Education</span>
          </span>

          <span className="text-colorGray max-w-[650px] leading-[30px]">
            I design products that are more than pretty. I make them usable &
            fit to your demand, I make the best ones.
          </span>

          <div className="w-full flex flex-col md:flex-row gap-x-10 mt-2 border md:border-none border-metalBlack rounded-xl p-6 md:p-0">
            <div className="w-full md:w-2/6 md:h-40 md:border-r border-metalBlack relative">
              <div className="hidden md:block absolute -right-1">
                <div className="w-2 h-2 bg-themeColor rounded-full outline outline-8 outline-greyBlack"></div>
              </div>

              <div className="flex w-full md:w-fit justify-between md:justify-start md:flex-col -mt-2">
                <span className="text-snowWhite text-[16px] font-medium pr-2">
                  Ferdowsi of Mashhad University
                </span>
                <span className="text-colorGray text-[14px]">
                  2021 - Current
                </span>
              </div>
            </div>
            <div className="w-full md:w-4/6 md:pl-10 mt-7 md:-mt-2.5">
              <div className="flex flex-col gap-y-2">
                <span className="text-snowWhite font-medium text-xl">
                  Bachelor in Computer Science
                </span>
                <span className="text-colorGray leading-[30px]">
                  Owing to advancements in product other designer technologies
                  aute voluptate.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
