import React from "react";
import ProgressProvider from "./common/ProgressProvider";

const Line = ({ from, to, step, stepDuration, direction }) => {
  return (
    <div className="h-screen border border-dashed border-metalBlack relative">
      <ProgressProvider
        valueStart={from}
        valueEnd={to}
        stepDuration={stepDuration}
        stepDistance={direction === "down" ? step : step * -1}
        repeatable
      >
        {(value) => (
          <div
            className={`absolute w-[9.5px] h-[9.5px] bg-themeColor rounded-sm -rotate-45 -left-[5px]`}
            style={{ top: `${value}%` }}
          />
        )}
      </ProgressProvider>
    </div>
  );
};

export default function BackgroundAnimation() {
  const step = 0.025;
  const duration = 8;

  return (
    <div className="left-[6.5%] md:left-[20%] w-[85%] md:w-[60%] absolute flex justify-between overflow-hidden px-5">
      <div className="">
        <Line
          from={-5}
          to={105}
          step={step}
          stepDuration={duration}
          direction="down"
        />
      </div>
      <div className="">
        <Line
          from={120}
          to={-5}
          step={step}
          stepDuration={duration}
          direction="up"
        />
      </div>
      <div className="">
        <Line
          from={-25}
          to={110}
          step={step}
          stepDuration={duration}
          direction="down"
        />
      </div>
      <div className="">
        <Line
          from={115}
          to={-5}
          step={step}
          stepDuration={duration}
          direction="up"
        />
      </div>
    </div>
  );
}
