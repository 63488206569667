import { useEffect, useState } from "react";

const ProgressProvider = ({
  valueStart,
  valueEnd,
  children,
  disabled,
  stepDuration,
  stepDistance,
  repeatable,
}) => {
  const [intervalId, setIntervalId] = useState();
  const [value, setValue] = useState(valueStart);
  const step = stepDistance || 1;

  const run = () => {
    if (!disabled) {
      setIntervalId(
        setInterval(() => {
          if (step < 0) {
            setValue((prev) =>
              prev + step >= valueEnd
                ? prev + step
                : repeatable
                ? valueStart
                : prev
            );
          } else {
            setValue((prev) =>
              prev + step <= valueEnd
                ? prev + step
                : repeatable
                ? valueStart
                : prev
            );
          }
        }, stepDuration || 6)
      );
    }
  };

  const [reset, setReset] = useState(false);
  useEffect(() => {
    if (reset) {
      setValue(valueStart);
    }
  }, [reset]);

  useEffect(() => {
    run();
  }, []);

  useEffect(() => {
    run();
  }, [disabled]);

  useEffect(() => {
    if (value >= valueEnd) {
      clearInterval(intervalId);
      setIntervalId(null);
    }
  }, [value]);

  return children(value);
};

export default ProgressProvider;
